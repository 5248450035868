import { APIConfiguration } from '../utils/environment';
import { AdminApi, PipelineApi, SigmaApi } from './generated';
import { StacApi } from './stac-stub';

export const SigmaAPI = new SigmaApi(APIConfiguration);
export const AdminAPI = new AdminApi(APIConfiguration);
export const PipelineAPI = new PipelineApi(APIConfiguration);
export const StacAPI = new StacApi(APIConfiguration);

export * from './osk-types';
