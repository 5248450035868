import { Geometry, GeometryCollection, OSKGeoJson, OSKScene, noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { Footprint, MapCentroid } from '~/atoms';
import EditableMap from '~/organisms/map/EditableMap';
import { RootState } from '~/redux/store';

export type MapTabProps = {
    /** The list of captures to render on the map */
    scenes?: OSKScene[];
};
export const MapTab = ({ scenes }: MapTabProps) => {
    const itemList = (scenes ?? []).filter((scene) => !scene.geometry);
    const geometries = itemList.map((scene) =>
        OSKGeoJson.fromAPIGeometry(scene.geometry as Geometry | GeometryCollection),
    );

    return (
        <EditableMap useOSKLowbar={false}>
            {geometries.map((geojson) => {
                return <Footprint key={`footprint_${geojson.getCentroid()}`} visible={true} shape={geojson} />;
            })}
            <MapCentroid area={geometries}></MapCentroid>
        </EditableMap>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        scenes: state.data.orders.activeOrder?.scenes,
    };
};
export default connect(mapStateToProps, noop)(MapTab);
