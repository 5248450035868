import { OSKGeoJson } from '../models';
import { BASE_PATH, BaseAPI } from './generated/base';
import { getBounds } from '../utils/transforms/footprints';
import { Configuration } from './generated';
import globalAxios, { AxiosInstance } from 'axios';
import { getProgramId } from '../utils/programs';

const PLANETARY_COMPUTER_URL = 'https://planetarycomputer.microsoft.com/api/stac/v1';

export const DEFAULT_STAC_COLLECTION = 'landsat-c2-l1';

export type StacCollectionRequest = {
    collectionId?: string;
};

export type StacSearchItemRequest = {
    program: number;
    capturedAfter?: Date;
    capturedBefore?: Date;
    sensor?: number[];
    cloudCover?: number;
    aoiName?: string;
    aoi?: OSKGeoJson;

    // /search properties
    required?: string[];
    properties?: any;
    allOf?: any;
};

/**
 * StacApi - object-oriented interface
 * @export
 * @class StacApi
 * @extends {BaseAPI}
 */
export class StacApi extends BaseAPI {
    constructor(
        configuration?: Configuration,
        protected basePath: string = BASE_PATH,
        protected axios: AxiosInstance = globalAxios,
    ) {
        super(configuration, basePath, axios);

        if (configuration) {
            this.configuration = configuration;

            const programId = getProgramId();

            if (programId > 0) {
                this.basePath = `${configuration.basePath || this.basePath}/stac/programs/${programId}`;
            }
        }
    }

    // TODO: Needs filter?
    public async getCollection(options: StacCollectionRequest) {
        const { collectionId } = options;

        return fetch_json(`${PLANETARY_COMPUTER_URL}/collections/${collectionId}`);
    }

    public async searchCatalog(options: StacSearchItemRequest) {
        let collection: string = DEFAULT_STAC_COLLECTION;

        // Simulate collection switch for program
        if (options.program === 6) {
            collection = 'sentinel-2-l2a';
        }

        const box = getBounds(options.aoi ? [options.aoi] : []);
        const params = {
            limit: 10,
            collections: [collection],
            //datetime: options.capturedBefore?.toISOString(),
            //bbox: [box[0][1], box[0][0], box[1][1], box[1][0]],
        };

        // Example call for our own internal Stac endpoints
        //return fetch_json(`${this.basePath}/collections`).then((response) => console.info(response));

        // NOTE: STAC POST CALLS MUST BE content-type: applicaton/json
        // Example call for an external stac endpoint (Planetary Computer)
        // return fetch_json(`${PLANETARY_COMPUTER_URL}/search`, {
        //     method: 'POST',
        //     headers: { 'content-type': 'application/json' },
        //     body: JSON.stringify(params),
        // });

        // Example hardcoded array of Stac objects
        return Promise.resolve({
            features: [DUMMY_FEATURE],
        }) as Promise<any>;
    }
}

const fetch_json = (url: string, init?: RequestInit) => {
    return fetch(url, init).then((response) => response.json());
};

/*
    "[This] isn't a totally valid item since it doesn't define the "links" section
     nor an created/updated field since that will be handled by the stac-server
     at ingest. The above is the output of the transformer" - Will E
*/
const DUMMY_FEATURE = {
    type: 'Feature',
    stac_version: '1.0.0',
    id: '364655da-2e59-4678-a3f1-8b001ebbad95',
    description: 'Orbital Sidekick hyperspectral imaging scene',
    geometry: {
        type: 'Polygon',
        coordinates: [
            [
                [-102.27814934196195, 33.47965842780515],
                [-102.2331155400698, 33.485867850622945],
                [-102.17059891477074, 33.80531928667862],
                [-102.21574592867373, 33.79909870859723],
                [-102.27814934196195, 33.47965842780515],
            ],
        ],
    },
    bbox: [-102.27814934196195, 33.47965842780515, -102.17059891477074, 33.80531928667862],
    properties: {
        datetime: '2024-11-19T21:05:31Z',
        created: '2025-02-05T11:35:38.424088',
        updated: '2025-02-05T11:35:38.424089',
        platform: 'GHOST-4',
        sensor_type: 'hyperspectral',
        instruments: 'hsi',
        data_type: 'Float32',
        unit: 'Watts/(steradian*meters^2*micron)',
        'eo:cloud_cover': 0.5,
        'proj:code': 'EPSG:4326',
        'sat:orbit_state': 'descending',
        'view:sun_azimuth': 219.41905427525933,
        'view:sun_elevation': 25.75808409263917,
        'view:off_nadir': 6.652779935970797,
        'view:azimuth': 100.30162647707652,
        'osk:task_id': '8aa7759f-3703-4cda-89dd-2ca9b8a31495',
        'osk:band_count': 472,
        'osk:interleave': 'bsq',
        'osk:sensor_serial': '10',
        'osk:along_track_gsd': 44.74933538727088,
        'osk:cross_track_gsd': 8.297025354441688,
        'osk:angle_to_north': 167.8139917030985,
        'osk:dem_centroid': 1046.340087890625,
        'osk:dem_min_elevation': 1021.710205078125,
        'osk:dem_max_elevation': 1064.3824462890625,
        'osk:dem_mean_elevation': 1044.5774517792795,
        'osk:frame_rate': 847.0,
        'osk:frame_rate_units': 'hz',
        'osk:integration_time': 6449.699401855469,
        'osk:integration_time_units': '\u00b5s',
        'osk:fpa_col_start': 100,
        'osk:fpa_col_end': 571,
        'osk:fpa_row_start': 0,
        'osk:fpa_row_end': 511,
    },
    links: [
        {
            rel: 'self',
            href: './8AA7759F37034CDA89DD2CA9B8A31495__000000C9__00000004__1732050336-51099354_calibrated.json',
        },
        {
            rel: 'collection',
            href: '../ghost-hsi/ghost-hsi.json',
        },
        {
            rel: 'root',
            href: '../catalog.json',
        },
        {
            rel: 'parent',
            href: '../catalog.json',
        },
    ],
    assets: {
        datacube: {
            href: '/Users/sarko/Downloads/orb_match/create_rpc/data/8AA7759F37034CDA89DD2CA9B8A31495__000000C9__00000004__1732050336-51099354_calibrated.hsi',
            type: 'application/x-envi-hdr',
            title: 'ENVI Hyperspectral Image',
            roles: ['data', 'radiance'],
            description: 'ENVI Standard Format Hyperspectral Image',
            bands: [
                {
                    name: 'band_001',
                    'eo:common_name': null,
                    'eo:center_wavelength': 396.1,
                    'eo:full_width_half_max': 7.9,
                    minimum: 24.061452865600586,
                    maximum: 45.47093963623047,
                    mean: 30.384807586669922,
                    std: 1.7041300535202026,
                    nodata: 0,
                },
                {
                    name: 'band_002',
                    'eo:common_name': null,
                    'eo:center_wavelength': 400.6,
                    'eo:full_width_half_max': 7.9,
                    minimum: 33.03879928588867,
                    maximum: 59.687782287597656,
                    mean: 40.2018928527832,
                    std: 2.0882437229156494,
                    nodata: 0,
                },
                {
                    name: 'band_471',
                    'eo:common_name': null,
                    'eo:center_wavelength': 2500.8,
                    'eo:full_width_half_max': 10.5,
                    minimum: 0.02290707640349865,
                    maximum: 0.5492025017738342,
                    mean: 0.25905656814575195,
                    std: 0.044798679649829865,
                    nodata: 0,
                },
                {
                    name: 'band_472',
                    'eo:common_name': null,
                    'eo:center_wavelength': 2505.3,
                    'eo:full_width_half_max': 10.5,
                    minimum: 0.031616803258657455,
                    maximum: 0.4159373939037323,
                    mean: 0.1965836137533188,
                    std: 0.03224178031086922,
                    nodata: 0,
                },
            ],
        },
        datacube_header: {
            href: null,
            type: 'text/plain',
            title: 'ENVI Header File',
            roles: ['metadata'],
            description: 'ENVI Header Metadata File',
        },
        geoloc: {
            href: '/Users/sarko/Downloads/orb_match/create_rpc/data/8AA7759F37034CDA89DD2CA9B8A31495__000000C9__00000004__1732050336-51099354_igm',
            type: 'application/x-envi-hdr',
            title: 'ENVI Geolocation Array File',
            roles: ['data', 'location', 'latitude', 'longitude'],
            description: 'ENVI geolocation array file',
            bands: [
                {
                    name: 'WGS-84_lat',
                    'eo:common_name': 'latitude',
                    minimum: 33.47965842780515,
                    maximum: 33.80531928667862,
                    mean: 33.64145358331159,
                    std: 0.09203857302374112,
                    nodata: 0,
                },
                {
                    name: 'WGS-84_long',
                    'eo:common_name': 'longitude',
                    minimum: -102.27814934196195,
                    maximum: -102.17059891477074,
                    mean: -102.22479266975257,
                    std: 0.022160317406057423,
                    nodata: 0,
                },
            ],
        },
        geoloc_header: {
            href: null,
            type: 'text/plain',
            title: 'ENVI Header File',
            roles: ['metadata'],
            description: 'ENVI Header Metadata File',
        },
        browse: {
            href: '/Users/sarko/Downloads/stac//path/to/browse/browse.jpg',
            type: 'image/png',
            roles: ['browse'],
        },
    },
};
