export const PROGRAM_ID_LOCAL_STORAGE_KEY = 'user_session_program_id';

/*
    !!!
    TODO: Refactor all references of getProgramId to point to the OSKCore version.
    !!!
*/
export function getProgramId() {
    // First, check the url
    const urlPaths = window.location.pathname.split('/');
    // NOTE: The -1 here is because we do i and i+1. This prevents NPE.
    for (let i = 0; i < urlPaths.length - 1; i++) {
        const firstTerm = urlPaths[i];
        const secondTerm = parseInt(`${urlPaths[i + 1]}`);

        // If we encounte program/number then we can safely assume
        // that number is the programId because all of our routes
        // (at the time of this writing) follow such a pattern.
        if (firstTerm.toLowerCase() === 'program' && !isNaN(secondTerm)) {
            localStorage.setItem(PROGRAM_ID_LOCAL_STORAGE_KEY, `${secondTerm}`);
            return secondTerm;
        }
    }

    const activeProgramId = localStorage.getItem(PROGRAM_ID_LOCAL_STORAGE_KEY);
    if (activeProgramId !== null) {
        return parseInt(activeProgramId);
    } else {
        return -1;
    }
}
