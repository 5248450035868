// Sigma Data
import sigmaApp from './modules/data/app';
import cart from './modules/data/cart';
import library from './modules/data/library';
import map from './modules/data/map';
import nav from './modules/data/nav';
import search from './modules/data/search';
import orders from './modules/data/orders';

import monitorApp from './modules/monitor/app';
import monitorReports from './modules/monitor/reports';
import taskingApp from './modules/tasking/app';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import osk from './modules/osk';
import session from './modules/session';

const store = configureStore({
    reducer: {
        osk,
        session,

        // SIGMA DATA
        data: combineReducers({
            app: sigmaApp,
            library,
            map,
            cart,
            nav,
            search,
            orders,
        }),

        // TASKING
        tasking: combineReducers({
            app: taskingApp,
        }),

        // SIGMA MONITOR
        monitor: combineReducers({
            app: monitorApp,
            reports: monitorReports,
        }),
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
