import React from 'react';
import { TimelineSlider, TimelineSliderProps } from '~/molecules/map/TimelineSlider';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { noop } from 'oskcore';
import { selectResults } from '~/redux/modules/data/search';

const ConnectedTimelineSlider = ({ scenes, visible, ...props }: TimelineSliderProps) => {
    return <TimelineSlider scenes={scenes} visible={visible} {...props} />;
};

const mapStateToProps = (state: RootState) => {
    const { timelineScrubberVisible } = state.data.search;
    return {
        scenes: selectResults(state),
        visible: timelineScrubberVisible,
    };
};

export default connect(mapStateToProps, noop)(ConnectedTimelineSlider);
