import { TimelineBucket } from '.';
import { utcToZonedTime } from 'date-fns-tz';
import { date_format_longer } from '~/utils';
import { OSKScene } from 'oskcore';

/** Given an OSKScene[], create a list of TimelineBuckets that represent the groups they fall into. */
export const bucketByDateNoGaps = (scenes: OSKScene[]): TimelineBucket[] => {
    const b: any[] = new Array(50).fill(0).map(() => ({ value: 0, date: new Date() }));
    if (!scenes || scenes.length === 0) return b;

    // First bucket by taskid
    const taskBuckets: {
        [id: string]: OSKScene[];
    } = {};
    scenes?.forEach((scene: OSKScene) => {
        const task_id = scene.task_id;
        if (!(task_id in taskBuckets)) {
            taskBuckets[task_id] = [];
        }
        taskBuckets[task_id].push(scene);
    });

    // Then bucket by date, using the oldest date for all scenes with a given taskid
    let minDate: Date = new Date(scenes[0].datetime);
    let maxDate: Date = new Date(scenes[0].datetime);

    const dayBuckets: {
        [id: string]: OSKScene[];
    } = {};
    scenes?.forEach((scene: OSKScene) => {
        const task_id = scene.task_id;
        const oldestDate: Date = taskBuckets[task_id]
            .map((item) => new Date(item.datetime))
            .reduce((oldest, acquisition_time) => (oldest < acquisition_time ? oldest : acquisition_time));

        const key = date_format_longer(oldestDate);
        if (oldestDate < minDate) minDate = oldestDate;
        if (oldestDate > maxDate) maxDate = oldestDate;
        if (!(key in dayBuckets)) {
            dayBuckets[key] = [];
        }

        dayBuckets[key].push(scene);
    });

    let minBucketSize = Number.MAX_SAFE_INTEGER;
    let maxBucketSize = 0;
    Object.values(dayBuckets).forEach((scenes: OSKScene[]) => {
        if (scenes.length < minBucketSize) minBucketSize = scenes.length;
        if (scenes.length > maxBucketSize) maxBucketSize = scenes.length;
    });

    const returnBuckets = Object.entries(dayBuckets).map((value) => {
        const bucketDate = new Date(value[0]);
        const scenes: OSKScene[] = value[1];
        const data: TimelineBucket = {
            value: (scenes.length - minBucketSize) / (maxBucketSize - minBucketSize),
            date: utcToZonedTime(bucketDate, 'UTC'),
        };
        return data;
    });

    return returnBuckets.sort((a, b) => (a.date > b.date ? 1 : -1));
};

/** Generate a CSS clip-path value for a given list of buckets. */
export const histogramGetClipPath = (buckets: TimelineBucket[], minValue = 0, maxValue = 1) =>
    `polygon(0 100%,${buckets.map((bucket, idx) => {
        const space = 100 / (buckets.length - 1);
        return `${idx * space}% ${Math.max(Math.min(100 - bucket.value * 100, minValue), maxValue)}%`;
    })}, 101% 100%)`;

/** Generate a CSS clip-path value for a given list of buckets in a selected sub-range. */
export const histogramGetSelectedClipPath = (
    buckets: TimelineBucket[],
    startIndex: number,
    endIndex: number,
    minValue = 0,
    maxValue = 1,
) =>
    `polygon(0 100%,${buckets
        .filter((bucket, idx) => idx >= startIndex && idx <= endIndex)
        .map((bucket, idx) => {
            const space = 100 / (buckets.length - 1);
            const returns: string[] = [];
            const i = idx + startIndex;

            if (i === startIndex) {
                returns.push(`${i * space - 0.01}% 100%`);
            }

            returns.push(`${i * space}% ${Math.max(Math.min(100 - bucket.value * 100, minValue), maxValue)}%`);

            if (i === endIndex) {
                returns.push(`${i * space + 0.01}% 100%`);
            }

            return returns;
        })}, 101% 100%)`;
