import { StacAsset, StacItem } from 'stac-ts';
import {
    GeoJSONPoint,
    GeoJSONLineString,
    GeoJSONPolygon,
    GeoJSONMultiPoint,
    GeoJSONMultiLineString,
    GeoJSONMultiPolygon,
    GeoJSONGeometryCollection,
} from 'stac-ts/src/types/geojson';

/**
 * This defines a "Scene" of captured imagery.
 */
export type OSKScene = {
    // stac_version? Unsure if needed.
    id: string;
    geometry:
        | GeoJSONPoint
        | GeoJSONLineString
        | GeoJSONPolygon
        | GeoJSONMultiPoint
        | GeoJSONMultiLineString
        | GeoJSONMultiPolygon
        | GeoJSONGeometryCollection
        | null;
    bbox?: number[];
    assets?: { [k: string]: StacAsset };

    /** Properties */
    datetime: string;
    created: string;
    updated: string;
    platform: string; // Sat *name* not id
    sensor_type: string;
    instruments?: string[];
    data_type: string;
    unit: string;
    cloud_cover: number;
    code: string;
    orbit_state: string;
    sun_azimuth: number;
    sun_elevation: number;
    off_nadir: number;
    azimuth: number;
    task_id: string;
    band_count: number;
    interleave: string;
    sensor_serial: string;
    along_track_gsd: number;
    cross_track_gsd: number;
    angle_to_north: number;
    dem_centroid: number;
    dem_min_elevation: number;
    dem_max_elevation: number;
    dem_mean_elevation: number;
    frame_rate: number;
    frame_rate_units: string;
    integration_time: string;
    integration_time_units: string;
    fpa_col_start: number;
    fpa_col_end: number;
    fpa_row_start: number;
    fpa_row_end: number;
};

export const DefaultScene: OSKScene = {
    id: '',
    geometry: null,
    bbox: [],
    assets: {},

    /** Properties */
    datetime: '',
    created: '',
    updated: '',
    platform: '',
    sensor_type: '',
    instruments: [''],
    data_type: '',
    unit: '',
    cloud_cover: 0,
    code: '',
    orbit_state: '',
    sun_azimuth: 0,
    sun_elevation: 0,
    off_nadir: 0,
    azimuth: 0,
    task_id: '',
    band_count: 0,
    interleave: '',
    sensor_serial: '',
    along_track_gsd: 0,
    cross_track_gsd: 0,
    angle_to_north: 0,
    dem_centroid: 0,
    dem_min_elevation: 0,
    dem_max_elevation: 0,
    dem_mean_elevation: 0,
    frame_rate: 0,
    frame_rate_units: '',
    integration_time: '',
    integration_time_units: '',
    fpa_col_start: 0,
    fpa_col_end: 0,
    fpa_row_start: 0,
    fpa_row_end: 0,
};

export const SceneFromStacItem = (stacItem: StacItem): OSKScene => {
    const { id, geometry, bbox, assets } = stacItem;

    const scene: OSKScene = {
        ...DefaultScene,
        id,
        geometry,
        bbox,
        assets,
        datetime: stacItem.properties['datetime'],
        created: stacItem.properties['created'],
        updated: stacItem.properties['updated'],
        platform: stacItem.properties['platform'],
        sensor_type: stacItem.properties['sensor_type'],
        instruments: stacItem.properties['instruments'],
        data_type: stacItem.properties['data_type'],
        unit: stacItem.properties['unit'],
        cloud_cover: stacItem.properties['eo:cloud_cover'],
        code: stacItem.properties['proj:code'],
        orbit_state: stacItem.properties['sat:orbit_state'],
        sun_azimuth: stacItem.properties['view:sun_azimuth'],
        sun_elevation: stacItem.properties['view:sun_elevation'],
        off_nadir: stacItem.properties['view:off_nadir'],
        azimuth: stacItem.properties['view:azimuth'],
        task_id: stacItem.properties['osk:task_id'],
        band_count: stacItem.properties['osk:band_count'],
        interleave: stacItem.properties['osk:interleave'],
        sensor_serial: stacItem.properties['osk:sensor_serial'],
        along_track_gsd: stacItem.properties['osk:along_track_gsd'],
        cross_track_gsd: stacItem.properties['osk:cross_track_gsd'],
        angle_to_north: stacItem.properties['osk:angle_to_north'],
        dem_centroid: stacItem.properties['osk:dem_centroid'],
        dem_min_elevation: stacItem.properties['osk:dem_min_elevation'],
        dem_max_elevation: stacItem.properties['osk:dem_max_elevation'],
        dem_mean_elevation: stacItem.properties['osk:dem_mean_elevation'],
        frame_rate: stacItem.properties['osk:frame_rate'],
        frame_rate_units: stacItem.properties['osk:frame_rate_units'],
        integration_time: stacItem.properties['osk:integration_time'],
        integration_time_units: stacItem.properties['osk:integration_time_units'],
        fpa_col_start: stacItem.properties['osk:fpa_col_start'],
        fpa_col_end: stacItem.properties['osk:fpa_col_end'],
        fpa_row_start: stacItem.properties['osk:fpa_row_start'],
        fpa_row_end: stacItem.properties['osk:fpa_row_end'],
    } as OSKScene;

    return scene;
};
