import React, { useState } from 'react';
import { Box, BoxProps, OSKIcon, OSKThemeType, Spinner, Typography } from 'oskcomponents';
import { Sensor, SigmaAPI } from 'oskcore';
import CSS from 'csstype';
import styled, { useTheme } from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { date_format_long, fetchArtifact, getProgramId, parseEnviHeader, utc_time_format_long } from '~/utils';
import { dequeueFile, enqueueFile, toggleFileIntent } from '~/redux/modules/data/cart';
import store, { AppDispatch, RootState } from '~/redux/store';
import { ImgTiff, CloudCoverageChip } from '~/atoms';
import { FaEye } from 'react-icons/fa';
import gdux, { GDUX_HIGHLIGHT_ADD, GDUX_HIGHLIGHT_REMOVE, GDUX_SELECT_ADD, GDUX_SELECT_REMOVE } from '~/gdux';
import { setEnviHeaderDisplay } from '~/redux/modules/data/search';
import toast from 'react-hot-toast';
import { OSKScene } from 'oskcore/src/api';

// TODO: Rename the actual file
export type SceneItemProps = {
    /** The fileId (capture.id) this item represents */
    fileId: string | number;
    /** The capture to populate this item with */
    data: OSKScene;
    /** A style to override the container with */
    style?: CSS.Properties;
    /** Whether this item is actively selected or not */
    selected?: boolean;
    /** Whether to hide the selection functionality or not */
    hideIcon?: boolean;
    /** If true, all click actions will only affect the DownloadIntent.skip flag */
    intentOnly?: boolean;
    /** A list of sensors */
    sensors: Sensor[];
    /** Optional click event to invoke when the item is selected */
    onClick?: (scene: OSKScene) => void;
    /** Optional event that's invoked when the thumbnail is clicked. */
    onThumbnailClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, scene: OSKScene) => void;
    /** The method to invoke when a footprint is clicked */
    onFootprintSelected?: (scene: OSKScene) => void;
} & Omit<Omit<BoxProps, 'ref'>, 'onClick'>;

export const SceneItem = ({
    fileId,
    intentOnly,
    selected,
    style,
    data,
    hideIcon,
    sensors,
    onClick,
    onThumbnailClick,
    onFootprintSelected,
    ...props
}: SceneItemProps) => {
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();
    const program = getProgramId();
    const sensorMap = sensors.reduce((acc, cur) => {
        acc[cur.osk_id] = cur.osk_sensor_name;
        return acc;
    }, {} as Record<number, string>);

    const [isEnviLoading, setIsEnviLoading] = useState<boolean>(false);

    if (!data) return <Box p={24}>Error Loading Data</Box>;

    const cloud_cover_pct = data.cloud_cover as number | undefined;

    return (
        <Box
            style={{
                justifyContent: 'space-around',
                padding: '8px',
                paddingLeft: '16px',
                paddingRight: '8px',
                borderLeft: `3px solid ${selected ? 'rgba(249, 102, 33, 0.7)' : 'transparent'}`,
                borderTop: `1px solid ${theme.colors.black200}`,
                position: 'relative',
                ...style,
            }}
            onMouseEnter={() => gdux.trigger(GDUX_HIGHLIGHT_ADD, fileId.toString())}
            onMouseLeave={() => gdux.trigger(GDUX_HIGHLIGHT_REMOVE, fileId.toString())}
            onClick={function (evt) {
                evt.stopPropagation();
                evt.preventDefault();

                if (intentOnly && data) {
                    dispatch(toggleFileIntent(`${data.id}`));
                } else {
                    onFootprintSelected && onFootprintSelected(data);
                }

                onClick && onClick(data);
            }}
            grow
            {...props}
            center="vertical"
        >
            <CaptureItemThumbnail
                // TODO: Reference the actual presigned artifact URL
                //thumbnail={data.preview_artifact}
                thumbnail=""
                {...(onThumbnailClick && { onClick: (e: any) => onThumbnailClick(e, data) })}
            />
            <Box
                style={{
                    paddingLeft: '10px',
                    justifyContent: 'space-around',
                    height: '120px',
                }}
                col
            >
                <Typography variant="heading4">{date_format_long(data.datetime)}</Typography>
                <Box style={{ color: theme.colors.black600 }}>
                    <OSKIcon code="clock" mr={8} />
                    <Typography variant="caption1">{utc_time_format_long(data.datetime)}</Typography>
                </Box>
                <Box style={{ color: theme.colors.black600 }}>
                    <OSKIcon code="ghost-2" mr={8} strokeWidth={1} />
                    <Typography variant="caption1">{sensorMap[parseInt(data.sensor_serial)]}</Typography>
                </Box>
                <CloudCoverageChip
                    value={
                        cloud_cover_pct === undefined || cloud_cover_pct === null
                            ? undefined
                            : Math.round(cloud_cover_pct)
                    }
                />
            </Box>
            <Box grow />
            {hideIcon !== true && (
                <Box center="all" w={40} style={{ marginRight: '7px' }}>
                    <OSKIcon fill={theme.colors.black600} code={selected ? 'trash' : 'plus'} />
                </Box>
            )}
            <CaptureItemInfoButton
                loading={isEnviLoading}
                onClick={() => {
                    setIsEnviLoading(true);
                    SigmaAPI.getCaptureArtifact({ id: data.id, program })
                        .then((result) => {
                            const { envi_header_artifact } = result.data;
                            if (envi_header_artifact) {
                                fetchArtifact(envi_header_artifact)
                                    .then(async (response) => {
                                        const header_text = await response.text();
                                        const header = parseEnviHeader(header_text);
                                        dispatch(setEnviHeaderDisplay(header));
                                        setIsEnviLoading(false);
                                    })
                                    .catch(() => {
                                        setIsEnviLoading(false);
                                        toast.error('Failed to load ENVI header information for that capture.');
                                    });
                            }
                        })
                        .catch(() => {
                            setIsEnviLoading(false);
                            toast.error('Failed to load ENVI header information for that capture.');
                        });
                }}
            />
        </Box>
    );
};

type CaptureItemInfoButton = {
    /** Styled-component passthrough */
    className?: string;
    /** A flag that's set if we should show the button as 'loading' */
    loading?: boolean;
    /** A method that's called when the button is clicked */
    onClick?: () => void;
};

const CaptureItemInfoButton = styled(({ className, loading, onClick, ...props }: any) => {
    return (
        <Box
            {...props}
            style={{
                position: 'absolute',
                right: '0px',
                top: '0px',
                width: '40px',
                height: '40px',
            }}
            onClick={(e) => {
                if (!loading) {
                    e.preventDefault();
                    e.stopPropagation();

                    onClick && onClick();
                }
            }}
            center="all"
            className={className}
        >
            {loading ? <Spinner variant="Circle" size="Tiny" /> : <OSKIcon code="info-circle-inverted" scale={175} />}
        </Box>
    );
})`
    &:hover {
        opacity: 0.2;
    }
`;

type ThumbnailProps = {
    /** The thumbnail URL*/
    thumbnail: string | null;
    /** A method that's called when the thumbnail is clicked */
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    /** Styled-component passthrough */
    className?: string;
};
const CaptureItemThumbnail = styled(({ thumbnail, onClick, className, ...props }: ThumbnailProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box
            col
            className={className}
            onClick={(e) => onClick && onClick(e)}
            style={{ position: 'relative' }}
            {...props}
        >
            <Box
                className="thumbnail-eye"
                style={{
                    position: 'absolute',
                    backgroundColor: theme.colors.gray0a,
                    width: '100%',
                    height: '100%',
                    zIndex: 100,
                    borderRadius: '7px',
                    fontSize: '2em',
                }}
                center="all"
            >
                <FaEye fill="white" />
            </Box>
            {thumbnail && <ImgTiff src={thumbnail} width={125} height={125} loading={'lazy'} />}
            {!thumbnail && (
                <img
                    src="/images/no-image.png"
                    title="There is no thumbnail generated for this capture"
                    width={125}
                    height={125}
                    loading="lazy"
                />
            )}
        </Box>
    );
})`
    .thumbnail-eye {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }
    ${(props: any) =>
        props.onClick &&
        `.thumbnail-eye:hover {
            opacity: 1;
        }`}
`;

const mapStateToProps = (state: RootState, ownProps: Partial<SceneItemProps>) => {
    const { fileId } = ownProps;
    const enqueuedItem = state.data.cart.enqueued[fileId ?? '-1'];

    return {
        selected: enqueuedItem ? enqueuedItem.skip !== true : false,
        data: state.data.search.resultMap[fileId ?? '-1'],
        sensors: state.osk.sensors,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        onFootprintSelected: (scene: OSKScene) => {
            const file_id = scene.id;
            const task_id = scene.task_id;
            if (store.getState().data.cart.enqueued[file_id]) {
                dispatch(dequeueFile(file_id));
                gdux.trigger(GDUX_SELECT_REMOVE, scene.id);
            } else {
                dispatch(enqueueFile({ fileId: file_id, taskId: task_id }, scene));
                gdux.trigger(GDUX_SELECT_ADD, scene.id);
            }
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SceneItem);
