import { Box, OSKThemeType, Spinner, Text } from 'oskcomponents';
import { OrderStatus, noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import { OrderStatusMessageMap, SigmaOrderDetails, selectOrderStatusCode } from '~/redux/modules/data/orders';
import { RootState } from '~/redux/store';
import { SummaryTabSection } from './SummaryTabSection';
import { format } from 'date-fns';

export type SummaryTabProps = {
    /** From redux, whether the order is currently loading */
    orderLoading?: boolean;
    /** From redux, the active order */
    activeOrder?: SigmaOrderDetails;
};

export const SummaryTab = ({ activeOrder, orderLoading }: SummaryTabProps) => {
    const theme = useTheme() as OSKThemeType;

    const totalImagery = activeOrder?.scenes?.length ?? 0;
    const s3Bucket = activeOrder?.s3Bucket ?? 'None';
    const s3Folder = activeOrder?.s3Folder ?? '';
    const fileFormats: string = activeOrder?.fileFormat ?? 'None';
    const productLevels: string = activeOrder?.productLevel ?? 'None';

    const { reqLineStatusText, reqLineStatusCode } = activeOrder?.orderStatus as OrderStatus;
    const statusColor = OrderStatusMessageMap[selectOrderStatusCode(activeOrder?.orderStatus)].color;
    const showOrderStatus = (reqLineStatusText || reqLineStatusCode) && statusColor;

    if (orderLoading) {
        return (
            <Box center="all" grow style={{ width: '100%', height: '100%' }}>
                <Spinner size="Large" variant="Box" />
            </Box>
        );
    } else {
        return (
            <Box col>
                <Box
                    style={{
                        marginTop: '20px',
                        paddingBottom: '20px',
                    }}
                    col
                >
                    {activeOrder?.orderType === 'archive' && (
                        <SummaryTabSection>
                            <Box>
                                <Text style={{ width: '250px', fontWeight: 'bold' }}>Total Imagery</Text>
                                <Text style={{ flexGrow: 1 }}>{totalImagery}</Text>
                            </Box>
                        </SummaryTabSection>
                    )}
                    <SummaryTabSection>
                        <Box center="vertical">
                            <Text style={{ fontWeight: 'bold', width: '250px' }}>Destination Bucket</Text>
                            <Text style={{ flexGrow: 1 }}>
                                <span style={{ color: theme.colors.gray80 }}>s3://</span>
                                {s3Bucket.replace('s3://', '').replace('S3://', '')}/{s3Folder}
                            </Text>
                        </Box>
                    </SummaryTabSection>
                    <SummaryTabSection>
                        <Box center="vertical">
                            <Text style={{ fontWeight: 'bold', width: '250px' }}>Product Level(s)</Text>
                            <Text
                                style={{
                                    flexGrow: 1,
                                }}
                            >
                                {productLevels}
                            </Text>
                        </Box>
                    </SummaryTabSection>
                    <SummaryTabSection>
                        <Box center="vertical">
                            <Text style={{ fontWeight: 'bold', width: '250px' }}>File Type(s)</Text>
                            <Text style={{ flexGrow: 1 }}>{fileFormats}</Text>
                        </Box>
                    </SummaryTabSection>
                    {activeOrder?.orderTimeStamp && (
                        <SummaryTabSection>
                            <Box center="vertical">
                                <Text style={{ fontWeight: 'bold', width: '250px' }}>Order Placed</Text>
                                <Text style={{ flexGrow: 1 }}>
                                    {format(new Date(activeOrder.orderTimeStamp), 'MMMM d, yyy h:mm a')}
                                </Text>
                            </Box>
                        </SummaryTabSection>
                    )}
                    {activeOrder && (
                        <SummaryTabSection>
                            <Box center="vertical">
                                <Text style={{ fontWeight: 'bold', width: '250px' }}>Order Id</Text>
                                <Text style={{ flexGrow: 1 }}>{activeOrder?.id}</Text>
                            </Box>
                        </SummaryTabSection>
                    )}
                    {showOrderStatus && (
                        <>
                            <SummaryTabSection>
                                <Box center="vertical">
                                    <Text style={{ fontWeight: 'bold', width: '250px' }}>Order Status Code</Text>
                                    <Text style={{ flexGrow: 1 }} color={statusColor}>
                                        {reqLineStatusCode}
                                    </Text>
                                </Box>
                            </SummaryTabSection>
                            <SummaryTabSection>
                                <Box center="vertical">
                                    <Text style={{ fontWeight: 'bold', width: '250px' }}>Order Status</Text>
                                    <Text style={{ flexGrow: 1 }} color={statusColor}>
                                        {reqLineStatusText}
                                    </Text>
                                </Box>
                            </SummaryTabSection>
                        </>
                    )}
                </Box>
            </Box>
        );
    }
};

const mapStateToProps = (state: RootState): Partial<SummaryTabProps> => {
    return {
        activeOrder: state.data.orders.activeOrder,
        orderLoading: state.data.orders.loadingSingleOrder,
    };
};

export default connect(mapStateToProps, noop)(SummaryTab);
