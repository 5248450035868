import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, OSKIcon, OSKThemeType } from 'oskcomponents';
import { GlobalZIndex } from 'oskcomponents/src/constants';
import { useTheme } from 'styled-components';
import { useDisableFeatureOnMouseOver } from '~/hooks';
import { TimelineSliderGrabber } from './TimelineSliderGrabber';
import { FadeInDate } from './FadeInDate';
import { bucketByDateNoGaps, histogramGetClipPath, histogramGetSelectedClipPath } from './bucket';
import { useDispatch } from 'react-redux';
import { setTimelineScrubberVisible } from '~/redux/modules/data/search';
import { OSKScene } from 'oskcore';

type TimelineSliderProps = {
    /** The list of results from the search. */
    scenes?: OSKScene[];

    /** A 0-1 percantage that caps the histogram to a certain minimum height. */
    minChartValue?: number;

    /** A 0-1 percantage that caps the histogram to a certain maximum height. */
    maxChartValue?: number;

    /** The method that's called when the date range is updated. */
    onRangeUpdated?: (startDate?: Date, endDate?: Date) => void;

    /** Whether or not to display this component. */
    visible?: boolean;
};

type TimelineBucket = {
    /** A percentage, showing the relative size of this bucket compared to other buckets. */
    value: number;

    /** The exact date that this bucket represents. */
    date: Date;
};

const TimelineSlider = ({
    scenes,
    minChartValue = 0.15,
    maxChartValue = 0.85,
    visible = false,
    onRangeUpdated,
    ...props
}: TimelineSliderProps) => {
    const MIN_CHART_VALUE = 100 - minChartValue * 100;
    const MAX_CHART_VALUE = 100 - maxChartValue * 100;
    const CHART_SIDE_PADDING = 60;
    const CHART_TICK_HEIGHT = 14;
    const CHART_GRAPH_HEIGHT = 60;

    const theme = useTheme() as OSKThemeType;
    const ref = useRef<any>(null);
    const dispatch = useDispatch();

    const [startRange, setStartRange] = useState(0); // bucket index
    const [endRange, setEndRange] = useState(0); // bucket index
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();
    const [boxWidth, setBoxWidth] = useState(0);
    const [, updateState] = React.useState({}); // Used for updating the page once
    const update = React.useCallback(() => updateState({}), []);

    const OnWindowResize = () => {
        update();
    };

    useEffect(() => {
        window.addEventListener('resize', OnWindowResize);

        return () => {
            window.removeEventListener('resize', OnWindowResize);
        };
    }, []);

    const buckets: TimelineBucket[] = useMemo(() => bucketByDateNoGaps(scenes ?? []), [scenes]);

    const tickSpacing = useMemo(
        () => (boxWidth ?? 1) / ((buckets?.length ?? 1) - 1),
        [boxWidth, buckets?.length, scenes],
    );

    useEffect(() => {
        if (buckets.length > 0) {
            setStartRange(0);
            setEndRange(buckets.length - 1);

            let minDate: Date = new Date();
            let maxDate: Date = new Date();

            buckets.forEach((bucket) => {
                if (bucket.date < minDate) minDate = bucket.date;
                if (bucket.date > maxDate) maxDate = bucket.date;
            });

            setStartDate(minDate);
            setEndDate(maxDate);
        }

        update();
    }, [buckets]);

    useDisableFeatureOnMouseOver(ref, 'Click', visible);
    useDisableFeatureOnMouseOver(ref, 'Drag', visible);
    useDisableFeatureOnMouseOver(ref, 'Zoom', visible);

    return (
        <Box
            id="timeline-slider"
            ref={ref}
            {...props}
            style={{
                display: visible ? 'inherit' : 'none',
                position: 'absolute',
                bottom: '28px',
                left: '350px',
                width: 'calc(100% - 350px - 80px)',
                zIndex: GlobalZIndex.Overlay,
                userSelect: 'none',
                pointerEvents: 'auto',
            }}
            center="all"
        >
            <Box
                style={{
                    backgroundColor: 'white',
                    color: 'black',
                    margin: '0px 40px',
                    borderRadius: '5px',
                    width: '100%',
                }}
                draggable={false}
                center="horizontal"
                col
            >
                <Box
                    id="box-ref"
                    ref={(node) => {
                        setBoxWidth(node?.clientWidth ?? 0);
                    }}
                    style={{ width: `calc(100% - ${CHART_SIDE_PADDING}px)`, position: 'relative' }}
                >
                    <Box
                        style={{ position: 'absolute', top: '2px', right: `-${CHART_SIDE_PADDING / 2 - 4}px` }}
                        onClick={() => dispatch(setTimelineScrubberVisible(false))}
                    >
                        <OSKIcon code="times" fill={theme.colors.black400} />
                    </Box>
                    <Box
                        style={{
                            backgroundColor: theme.colors.lightGray,
                            width: '100%',
                            height: `${CHART_GRAPH_HEIGHT}px`,
                            clipPath: histogramGetClipPath(buckets, MIN_CHART_VALUE, MAX_CHART_VALUE),
                            zIndex: 0,
                        }}
                    />
                    <Box
                        style={{
                            position: 'absolute',
                            opacity: '.2',
                            backgroundColor: theme.colors.accent,
                            width: '100%',
                            height: `${CHART_GRAPH_HEIGHT}px`,
                            clipPath: histogramGetSelectedClipPath(
                                buckets,
                                startRange,
                                endRange,
                                MIN_CHART_VALUE,
                                MAX_CHART_VALUE,
                            ),
                            zIndex: 0,
                        }}
                    />
                </Box>
                <Box
                    style={{
                        position: 'relative',
                        width: `calc(100% - ${CHART_SIDE_PADDING}px)`,
                        justifyContent: 'space-between',
                    }}
                    center="horizontal"
                >
                    <Box
                        style={{
                            position: 'absolute',
                            left: `${startRange * tickSpacing}px`,
                            width: `${(endRange - startRange) * tickSpacing}px`,
                            border: `2px solid ${theme.colors.accent}`,
                            top: '-2px',
                            // @ts-ignore
                            userDrag: 'none',
                            zIndex: 3,
                        }}
                    />
                    {boxWidth && (
                        <Box
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: `${CHART_GRAPH_HEIGHT}px`,
                                top: `-${CHART_GRAPH_HEIGHT}px`,
                                // @ts-ignore
                                userDrag: 'none',
                            }}
                        >
                            <TimelineSliderGrabber
                                index={startRange}
                                tickSpacing={tickSpacing}
                                height={CHART_GRAPH_HEIGHT}
                                onDragChange={(delta: number, finished: boolean) => {
                                    const deltaTicks = Math.trunc(delta / tickSpacing);

                                    let newRange = startRange + deltaTicks;
                                    if (newRange < 0) newRange = 0;
                                    if (newRange > endRange - 1) newRange = endRange - 1;

                                    setStartRange(newRange);

                                    if (finished) {
                                        let newStartDate: Date | undefined = buckets[newRange].date;
                                        if (newRange === 0) newStartDate = undefined;
                                        setStartDate(newStartDate);
                                        onRangeUpdated && onRangeUpdated(newStartDate, endDate);
                                    }
                                }}
                            />
                            <TimelineSliderGrabber
                                index={endRange}
                                tickSpacing={tickSpacing}
                                height={CHART_GRAPH_HEIGHT}
                                onDragChange={(delta: number, finished: boolean) => {
                                    const deltaTicks = Math.trunc(delta / tickSpacing);

                                    let newRange = endRange + deltaTicks;
                                    if (newRange >= buckets.length - 1) newRange = buckets.length - 1;
                                    if (newRange < startRange + 1) newRange = startRange + 1;

                                    setEndRange(newRange);

                                    if (finished) {
                                        let newEndDate: Date | undefined = buckets[newRange].date;
                                        if (newRange === buckets.length - 1) newEndDate = undefined;
                                        setEndDate(newEndDate);
                                        onRangeUpdated && onRangeUpdated(startDate, newEndDate);
                                    }
                                }}
                            />
                        </Box>
                    )}
                    {buckets.map((value, idx) => {
                        return (
                            <Box
                                key={`bucket-${idx}`}
                                style={{
                                    borderLeft: `1px solid ${
                                        idx >= startRange && idx <= endRange
                                            ? theme.colors.black600
                                            : theme.colors.black600
                                    }`,
                                    height: `${CHART_TICK_HEIGHT}px`,
                                    marginTop: `-${CHART_TICK_HEIGHT}px`,
                                }}
                            />
                        );
                    })}
                </Box>
                <Box style={{ height: '1px', width: '100%', backgroundColor: theme.colors.black600 }} />
                <Box
                    style={{
                        width: `calc(100% - ${CHART_SIDE_PADDING}px)`,
                        justifyContent: 'space-between',
                        height: '30px',
                        zIndex: 2,
                    }}
                    center="vertical"
                    grow
                >
                    {buckets.map((bucket, idx) => {
                        return (
                            <Box key={`bucket-label-${idx}`} style={{ width: '1px', justifyContent: 'center' }}>
                                <FadeInDate
                                    date={bucket.date}
                                    fade={idx !== startRange && idx !== endRange}
                                    style={(() => {
                                        if (tickSpacing < 50) {
                                            if (idx === startRange - 1 || idx === endRange - 1) {
                                                return {
                                                    paddingRight: '18px',
                                                };
                                            } else if (idx === startRange + 1 || idx === endRange + 1) {
                                                return {
                                                    paddingLeft: '18px',
                                                };
                                            }
                                        }
                                    })()}
                                />
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export { TimelineSlider };
export type { TimelineBucket, TimelineSliderProps };
